import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';

import Layout from '@layouts/';
import SEO from '@components/seo.jsx';
import Images from '@components/blog/images';
import Header from '@components/blog/header';
import Tags from '@components/blog/tags';
import Posts from '@components/blog/posts';
import Nav from '@components/blog/nav';

const Blog = ({ pageContext, data }) => {
  const intl = useIntl();
  const { humanPageNumber, nextPagePath, previousPagePath } = pageContext;

  return (
    <Layout
      langInfo={intl.locale === 'en'}
      switchPath={[
        `/blog${humanPageNumber !== 1 ? `/${humanPageNumber}` : ''}`,
        `/en/blog${humanPageNumber !== 1 ? `/${humanPageNumber}` : ''}`,
      ]}
    >
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'meta tags.title.blog' })}
        description={intl.formatMessage({ id: 'meta tags.description.blog' })}
      />
      <Images postsLength={data.allSanityPost.nodes.length} />
      <Header />
      <Tags locale={intl.locale} />
      <Posts posts={data.allSanityPost.nodes} locale={intl.locale} />
      <Nav nextPage={nextPagePath} prevPage={previousPagePath} locale={intl.locale} />
    </Layout>
  );
};

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allSanityPost(
      sort: {fields: date, order: DESC}
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        title_pl
        title_en
        _rawPostPreviewPl
        _rawPostPreviewEn
        slug_en {
          current
        }
        slug_pl {
          current
        }
        author {
          name
        }
        date
        mask_big {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
        post_tags {
          slug_en {
            current
          }
          slug_pl {
            current
          }
          name_pl
          name_en
        }
      }
    }
  }
`;

Blog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  pageContext: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired,
};

export default Blog;
