import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { customContainer } from '@utils/twin-macro';

const Wrapper = styled.div`
  ${customContainer}
  ${tw`relative`}
`;

const Beans = styled(Img)`
  ${tw`absolute! hidden xl:block`}
  ${({ hide }) => hide && tw`hidden!`}
`;

const BeansTop = styled(Beans)`
  top: 980px;
  left: -300px;
  ${tw`w-172`}
`;

const BeansMiddle = styled(Beans)`
  top: 1420px;
  left: -240px;
  transform: scaleX(-1);
  ${tw`w-150`}
`;

const BeansBottom = styled(Beans)`
  top: 1300px;
  right: -160px;
  ${tw`w-172`}
`;

const Images = ({ postsLength }) => {
  const data = useStaticQuery(graphql`
    query {
      beans: file(relativePath: { eq: "coffee-beans.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <BeansTop hide={postsLength < 2} fluid={data.beans.childImageSharp.fluid} />
      <BeansMiddle hide={postsLength < 3} fluid={data.beans.childImageSharp.fluid} />
      <BeansBottom hide={postsLength < 3} fluid={data.beans.childImageSharp.fluid} />
    </Wrapper>
  );
};

Images.propTypes = {
  postsLength: PropTypes.number.isRequired,
};

export default Images;
