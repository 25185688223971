import React from 'react';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Wrapper = styled.div`
  ${customContainer}
  ${tw`
    w-320 pt-40 px-10 pb-20 text-center
    sm:w-460
    md:w-auto md:pb-55 md:text-left
    lg:px-0 
  `}
`;

const Columns = tw.div`md:flex md:justify-between md:mt-24`;

const Column = styled(Text)`
  ${tw`block mt-15 md:w-360 md:mt-0 lg:w-480 xl:w-535`}
  
  b { ${tw`font-600`} }
`;

const Header = () => (
  <Wrapper>
    <Text fontFamily="bebasNeue" fontSizes={58} fontWeight={300} lineHeight={1.09} letterSpacing={72}>
      <FormattedMessage id="blog.title" />
    </Text>
    <Columns>
      <Column fontSizes={16} fontWeight={300} lineHeight={1.5} letterSpacing={20}>
        <FormattedHTMLMessage id="blog.left column" />
      </Column>
      <Column fontSizes={16} fontWeight={300} lineHeight={1.5} letterSpacing={20}>
        <FormattedHTMLMessage id="blog.right column" />
      </Column>
    </Columns>
  </Wrapper>
);

export default Header;
