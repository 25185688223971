import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { customContainer } from '@utils/twin-macro';

import Text from '@components/text';

const Wrapper = styled.div`
  ${customContainer}
  ${tw`
    w-320 px-10 pb-50 text-center
    sm:w-460
    md:w-auto md:text-left
    lg:px-0
  `}
`;

const TagsWrapper = tw.div`flex flex-wrap justify-center -mx-5 md:justify-start`;

const StyledLink = tw(Link)`mx-5 hover:opacity-80 transition-opacity duration-300`;

const Tag = tw(Text)`pt-10`;

const Tags = ({ locale }) => {
  const { allSanityTags } = useStaticQuery(graphql`
    query {
      allSanityTags(sort: {fields: _createdAt}) {
        nodes {
          name_en
          name_pl
          slug_en {
            current
          }
          slug_pl {
            current
          }
          id
        }
      }
    }
  `);

  const pathPart = locale === 'en' ? '/en/tag/' : '/tag/';
  const pathTo = (path) => `${pathPart}${path}`;

  return (
    <Wrapper>
      <TagsWrapper>
        {allSanityTags.nodes.map((tag) => (
          <StyledLink key={tag.id} to={pathTo(tag[`slug_${locale}`].current)}>
            <Tag color="blueDark" fontSizes={14} letterSpacing={9}>
              {tag[`name_${locale}`]}
            </Tag>
          </StyledLink>
        ))}
      </TagsWrapper>
    </Wrapper>
  );
};

Tags.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default Tags;
